import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/styles/components/text-title.scss';

const TextTitle = ({ fetchedData }) => {
  return (
    <div className="section text-title">
      {fetchedData.showTitle && (
        <span
          className="title"
          style={`color:${fetchedData.titleField.hex}`}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: fetchedData.title }}
        />
      )}
      {typeof fetchedData.textFieldNode !== 'undefined' && (
        <span
          style={{ color: `${fetchedData.textColor.hex}` }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: fetchedData.textFieldNode.internal.content }}
        />
      )}
    </div>
  );
};

TextTitle.propTypes = {
  fetchedData: PropTypes.shape().isRequired,
};

export default TextTitle;
