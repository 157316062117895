import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../../assets/styles/components/accordion.scss';

/* global window */
const Accordion = ({ fetchedData }) => {
  const [openAccordion, setOpenAccordion] = useState(fetchedData.openAsDefault);

  useEffect(async () => {
    let isMounted = true;
    const $accordionInner = window.document.querySelectorAll('.accordion-body-inner');

    if (isMounted) {
      if ($accordionInner !== null) {
        for (let i = 0; i < $accordionInner.length; i += 1) {
          if (isMounted) {
            const parent = $accordionInner[i].parentNode;
            const accordionBodyHeight = $accordionInner[i].offsetHeight;
            parent.style.height = `${accordionBodyHeight}px`;
          }
        }
      }
    }

    return () => { isMounted = false; };
  });

  return (
    <div className="container sm">
      <div className="product-accordion-wrapper">
        <div className={`accordion ${openAccordion ? 'open' : 'closed'}`}>
          <button
            type="button"
            className="strong accordion-title black-to-white"
            onClick={(e) => {
              e.preventDefault();
              setOpenAccordion(!openAccordion);
            }}
          >
            {fetchedData.title}
            <div className="cross-wrapper">
              <span className="black-to-white-bg horizontal" />
              <span className="black-to-white-bg vertical" />
            </div>
          </button>
          <div className="accordion-body">
            <div
              className="accordion-body-inner primary-to-secondary"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: fetchedData.textFieldNode.internal.content }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  fetchedData: PropTypes.shape().isRequired,
};

export default Accordion;
