import React from 'react';
import PropTypes from 'prop-types';
import Accordion from './blocks/accordion';
import ImageBlock from './blocks/image';
import TextImage from './blocks/textImage';
import TextTitle from './blocks/textTitle';
import VideoBlock from './blocks/video';
import FacebookBanner from './blocks/facebookBanner';

const ModularContent = ({ page }) => {
  const getComponent = (val) => {
    if (typeof val === 'undefined') {
      return null;
    }

    const components = {
      DatoCmsTextTitle: TextTitle,
      DatoCmsTextImage: TextImage,
      DatoCmsImage: ImageBlock,
      DatoCmsAccordion: Accordion,
      DatoCmsVideo: VideoBlock,
      DatoCmsFacebookLink: FacebookBanner,
    };

    return components[val];
  };

  return (
    <div className="modular-content">
      {page.blocks
        && page.blocks.map((block) => {
          const SpecificSection = getComponent(block.internal.type);
          return <SpecificSection fetchedData={block} />;
        })}
    </div>
  );
};

ModularContent.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default ModularContent;
