import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/styles/components/video.scss';

const VideoBlock = ({ fetchedData }) => {
  return (
    <div className="section video-block">
      <div className="video-wrapper">
        <iframe
          className="video"
          title={fetchedData.title}
          width="420"
          height="315"
          src={fetchedData.youtubeEmbedUrl}
        />
      </div>
    </div>
  );
};

VideoBlock.propTypes = {
  fetchedData: PropTypes.shape().isRequired,
};

export default VideoBlock;
