import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import '../../assets/styles/components/image.scss';

const ImageBlock = ({ fetchedData }) => {
  const imageFile = getImage(fetchedData.image);
  return (
    <div className="section image-block">
      <GatsbyImage image={imageFile} alt={fetchedData.image.alt || ''} />
    </div>
  );
};

ImageBlock.propTypes = {
  fetchedData: PropTypes.shape().isRequired,
};

export default ImageBlock;
