import React from 'react';
import PropTypes from 'prop-types';

const FacebookBanner = ({ fetchedData }) => {
  return (
    <iframe
      title={fetchedData.id}
      src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F${fetchedData.facebookGroupUrl}&tabs&width=280&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`}
      width="280"
      height="130"
      // eslint-disable-next-line react/style-prop-object
      style="border:none;overflow:hidden"
      scrolling="no"
      frameBorder="0"
      allowFullScreen="true"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    />
  );
};

FacebookBanner.propTypes = {
  fetchedData: PropTypes.shape().isRequired,
};

export default FacebookBanner;
