import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import '../../assets/styles/components/text-image.scss';

const TextImage = ({ fetchedData }) => {
  const imageFile = getImage(fetchedData.image);
  return (
    <div className="section text-image">
      <div
        className="body-text"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: fetchedData.textFieldNode.internal.content }}
      />
      <GatsbyImage image={imageFile} alt={fetchedData.image.alt || ''} />
    </div>
  );
};

TextImage.propTypes = {
  fetchedData: PropTypes.shape().isRequired,
};

export default TextImage;
